import revive_payload_client_2MyH4jEAVs from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qa2WOnKA1e from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zrURA3ooJL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_gwNm93srkA from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@20.14.14_sa_1d9cd58d43ab13a63ab70cf8c5429bc6/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_KnbnyyC8tH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yVU1rxcrzF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TpyiCKOXqP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vXf9fxa4fi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_J0g0aG2UqC from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.4_rollup@4.20.0_typescript@5.4.4_vue@3.4.35_typescript@5.4.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_0wb3ag7K5u from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_RrgheMaDC9 from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.4_@parcel+watcher@2.4.1_@type_a1160e2fbeae9b878a850b43cefdc9ce/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_Z4Ck2xwkwo from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import templates_pwa_client_bdb3b208_5SwHRbTTxu from "/opt/build/repo/.nuxt/templates.pwa.client.bdb3b208.ts";
import plugin_Evn6i0hg71 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_magicast@0.3.4_rollup@4.20.0_typescript@5.4.4_vue@3.4.35_typescript@5.4.4_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_qj2YYTfkF7 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.4.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_vUCJiMpkKZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.4.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
import setup_directives_client_fwoBYyxQ4Q from "/opt/build/repo/plugins/setup-directives.client.js";
export default [
  revive_payload_client_2MyH4jEAVs,
  unhead_qa2WOnKA1e,
  router_zrURA3ooJL,
  _0_siteConfig_gwNm93srkA,
  payload_client_KnbnyyC8tH,
  navigation_repaint_client_yVU1rxcrzF,
  check_outdated_build_client_TpyiCKOXqP,
  chunk_reload_client_vXf9fxa4fi,
  plugin_vue3_J0g0aG2UqC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0wb3ag7K5u,
  plugin_RrgheMaDC9,
  nuxt_plugin_Z4Ck2xwkwo,
  templates_pwa_client_bdb3b208_5SwHRbTTxu,
  plugin_Evn6i0hg71,
  switch_locale_path_ssr_qj2YYTfkF7,
  i18n_vUCJiMpkKZ,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et,
  setup_directives_client_fwoBYyxQ4Q
]