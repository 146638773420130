export default defineNuxtRouteMiddleware((to, from) => {
  const url = useRequestURL()
  if (url.search.includes('?s=')) {
    const nextRoute = `${process.env.BASE_URL}`
    return navigateTo(nextRoute, { external: true, redirectCode: 410 })
  }
  if (
    url.host === 'website-suntimes.netlify.app' ||
    url.host === 'sun-times.it' ||
    url.host === 'www.sun-times.it'
  ) {
    const nextRoute = `${process.env.BASE_URL}${to.fullPath}`
    return navigateTo(nextRoute, { external: true, redirectCode: 301 })
  }
})
