<template>
  <div class="footer-menu row-90-16">
    <div class="footer-menu-left">
      <h3
        class="title m-bottom-32px"
        ref="textElement"
        data-animate="reveal-text"
      >
        {{ $t('footer.menu.title') }}
      </h3>
      <div class="footer-menu-links text-caption show-for-large">
        <div class="footer-menu-links-group">
          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <a target="_blank" href="https://it.linkedin.com/company/sun-times"
              >Linkedin</a
            >
          </div>

          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <a href="mailto:info@suntimes.it">{{ $t('footer.menu.mail') }}</a>
          </div>
        </div>

        <div class="footer-menu-links-group">
          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <a target="_blank" href="https://www.instagram.com/suntimes.it/"
              >Instagram</a
            >
          </div>

          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes-green.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <nuxt-link :to="localePath({ name: 'be-suntimers' })">
              {{ $t('footer.menu.careers') }}
            </nuxt-link>
          </div>
        </div>

        <div class="footer-menu-links-group">
          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <a target="_blank" href="https://www.facebook.com/suntimes/"
              >Facebook</a
            >
          </div>
        </div>

        <div class="footer-menu-links-mbp">
          <img
            src="@/assets/img/logo/mbp-logo-white.svg"
            width="135"
            height="76"
            alt="Meta Business Partner Logo"
          />
        </div>
      </div>

      <div class="footer-menu-links text-caption hide-for-large">
        <div class="footer-menu-links-group">
          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <a target="_blank" href="https://it.linkedin.com/company/sun-times"
              >Linkedin</a
            >
          </div>

          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <a target="_blank" href="https://www.instagram.com/suntimes.it/"
              >Instagram</a
            >
          </div>

          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <a target="_blank" href="https://www.facebook.com/suntimes/"
              >Facebook</a
            >
          </div>

          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes-green.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <nuxt-link :to="localePath({ name: 'be-suntimers' })">
              {{ $t('footer.menu.careers') }}
            </nuxt-link>
          </div>

          <div class="footer-menu-links-group-item">
            <img
              src="@/assets/img/ico/emblema-suntimes.svg"
              width="13"
              height="13"
              :alt="runtimeConfig.public.siteName"
            />
            <a href="mailto:info@suntimes.it">{{ $t('footer.menu.mail') }}</a>
          </div>
        </div>
        <div class="footer-menu-links-mbp">
          <img
            src="@/assets/img/logo/mbp-logo-white.svg"
            width="135"
            height="76"
            alt="Meta Business Partner Logo"
          />
        </div>
      </div>
    </div>
    <div class="footer-menu-right">
      <nuxt-link :to="localePath({ name: 'index' })">
        <img
          src="@/assets/img/logo/logo-suntimes-verticale.svg"
          width="154"
          height="184"
          :alt="runtimeConfig.public.siteName"
        />
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterMenu',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { revealText } from '@/plugins/animation-directives/reveal-text.js' // Update with the correct path

const runtimeConfig = useRuntimeConfig()

const textElement = ref(null) // New ref for the text element

onMounted(() => {
  revealText(textElement.value, 'scroll', 'top bottom')
})
</script>

<style lang="scss">
@import './style.scss';
</style>
