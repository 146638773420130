import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/pages/runtime/validate.js";
import netlify_45redirects_45global from "/opt/build/repo/middleware/netlify-redirects.global.ts";
import remove_45trailing_45slash_45global from "/opt/build/repo/middleware/remove-trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_io_ca96338a044e05b184a298d88e816a7e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  netlify_45redirects_45global,
  remove_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}